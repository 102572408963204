.clr_green {
  color: #31d0aa;
}
.clr_red {
  color: #fc5252;
}
.clr_violet {
  color: #4b4ded;
}
.clr_grey {
  color: #8c8ca2;
}
.AppHomePage {
  margin-top: 70px;
}
.mt {
  margin-top: 10px;
}
.mb {
  margin-bottom: 10px;
}
.float-r {
  float: right;
}
.pt {
  padding-top: 10px;
}
.pt-20 {
  padding-top: 20px;
}
.form_error {
  background: #ff3c3c;
  padding: 6px 10px;
  margin-bottom: 15px;
  border-radius: 4px;
  color: #fff;
  font-size: 10px;
  transition: 0.3s ease-in-out;
}
.primary_title {
  font-weight: 500;
  font-size: 16px;
  color: #4a4a68;
  margin-bottom: 10px;
}
.edit_option_btn {
  border: none;
  background: transparent;
}
.edit_option_btn_drawer .MuiDrawer-paper {
  border-radius: 8px 8px 0px 0px;
}
.edit_option_head {
  background: #9d00c6;
  padding: 12px 17px 12px 17px;
  font-weight: 500;
  font-size: 18px;
  color: #ffffff;
  text-transform: capitalize;
}
.edit_option_tools_wrap {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding-left: 0;
  padding: 15px 25px;
  margin: 0;
}
.edit_option_tools_item {
  width: 33%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #8c8ca2;
  margin-bottom: 10px;
}
.edit_option_tools_item_text {
  font-weight: 400;
  font-size: 12px;
  text-transform: capitalize;
  margin-top: 4px;
}
.AppLabel {
  font-weight: 400;
  font-size: 12px;
  color: #8c8ca2;
  text-align: center;
  text-transform: capitalize;
}
.AppPage {
  margin-top: 15px;
  margin-bottom: 70px;
}
.AppHomePage {
  margin-top: 70px;
}
/* home */
.list_pages_wrap {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 10px 0 0 0;
  margin: 0;
}
.list_pages_item {
  width: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #8c8ca2;
}
.accounting_status_report_wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  list-style: none;
  background: #fff;
  border-radius: 5px;
  padding: 15px 10px;
}
.accounting_status_report_item {
  width: 31%;
  background: #ecf1f4;
  box-shadow: 0px 1px 2px rgba(255, 255, 255, 0.15),
    1px 1px 0px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}
.accounting_status_report_item a {
  padding: 10px 14px;
  width: 100%;
  display: block;
}
.accounting_status_main {
  font-weight: 700;
  font-size: 12px;
  color: #0e0e2c;
  margin-bottom: 3px;
  text-transform: capitalize;
}
.accounting_status_sub {
  font-weight: 400;
  font-size: 12px;
}
/* transaction card */
.transaction_card_wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  border-radius: 6px;
  padding: 5px 10px;
}
.transaction_card_details_wrap {
  display: flex;
  flex-direction: column;
  max-width: 70%;
}
.transaction_card_title {
  font-weight: 500;
  font-size: 14px;
  color: #0e0e2c;
  margin-bottom: 3px;
  text-transform: capitalize;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.transaction_card_order {
  font-weight: 400;
  font-size: 14px;
  color: #4a4a68;
  margin-bottom: 2px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.transaction_card_date {
  font-weight: 400;
  font-size: 12px;
  color: #8c8ca2;
}
.transaction_card_amount {
  font-weight: 500;
  font-size: 12px;
}
/* parties card */
.parties_card_wrap {
  background: #ffffff;
  border-radius: 6px;
  padding: 10px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.parties_card_details {
  display: flex;
  flex-direction: column;
}
.parties_card_name {
  font-weight: 500;
  font-size: 16px;
  color: #0e0e2c;
  margin-bottom: 2px;
  text-transform: capitalize;
}
.parties_card_label {
  font-weight: 400;
  font-size: 12px;
  color: #8c8ca2;
}
.parties_card_amount {
  font-weight: 500;
  font-size: 12px;
}
/* item card */
.item_card_wrap {
  display: flex;
  padding: 7px 7px;
  background: #ffffff;
  /* border-radius: 8px; */
  border-bottom: 2px solid #cccccc;
}
.item_card_wrap:last-child {
  border-bottom: none;
}
.item_card_img {
  /* width: 109px; */
  height: 80px;
  border-radius: 8px;
}
.item_card_img img {
  width: 100%;
  height: 100%;
}
.item_card_content_wrap {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  flex: 1;
  justify-content: space-between;
}
.item_card_name {
  font-weight: 500;
  font-size: 16px;
  color: #0e0e2c;
  text-transform: capitalize;
}
.item_card_content_child {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.item_card_details_item {
  font-weight: 500;
  font-size: 12px;
  color: #0e0e2c;

  margin-bottom: 2px;
}
.item_card_details_item_value {
  font-weight: 400;
  color: #4a4a68;
  margin-left: 4px;
  display: inline-block;
}
.item_card_details_item:last-child {
  margin-bottom: 0;
}
.item_card_delete_wrap svg {
  color: #4a4a68;
}
/* ------ */
.round_btn {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  color: #fff;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bg_blue {
  background: #4b4ded;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1), 0px 4px 8px rgba(0, 0, 0, 0.08),
    0px 1px 12px rgba(0, 0, 0, 0.04);
}
.bg_light_green {
  background: #31d0aa;
}
.btn {
  font-weight: 700;
  font-size: 16px;
  text-align: center;
  color: #ffffff;
  box-shadow: 0px 6px 2px -4px rgba(14, 14, 44, 0.1);
  border-radius: 43px;
  border: none;
  padding: 8px 10px;
  text-transform: uppercase;
}
.create_btns_wrap {
  display: flex;
}
.input_icon {
  font-weight: 700;
  font-size: 17px;
  color: #4a4a68;
}
.AppFloatingButtonWrapper {
  padding: 10px;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 60px;
  z-index: 99;
}
.create_drawer_top {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}
.create_option_drawer_child {
  padding: 10px;
}
.create_option_drawer_items_wrapper {
  padding: 0 20px;
}
.option_head {
  font-weight: 500;
  font-size: 15.498px;
  color: #0e0e2c;
}
.create_option_drawer_items_wrapper .edit_option_tools_wrap {
  padding: 15px 0;
  gap: 1%;
}
.create_option_drawer_items_wrapper .edit_option_tools_item {
  width: 24%;
}
.create_option_drawer_items_wrapper .AppLabel {
  font-size: 10px;
  text-transform: capitalize;
}
/* create invoice */
.invoice_top_box {
  display: flex;
  justify-content: space-between;
  background: #ffffff;
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 15px;
}
.invoice_top_box_details {
  display: flex;
  flex-direction: column;
  max-width: 90%;
}
.invoice_top_box_title {
  font-weight: 500;
  font-size: 16px;
  color: #0e0e2c;
  margin-bottom: 5px;
}
.invoice_top_box_desc {
  font-weight: 400;
  font-size: 12px;
  color: #8c8ca2;
}
.invoice_top_box_edit svg {
  color: #4a4a68;
}
.toggle_btns_wrap {
  display: flex;
  justify-content: space-between;
}

.field_split_wrap {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.field_split_wrap > * {
  min-width: 49%;
}
.pt-2 {
  padding-top: 20px;
}
.t-c {
  text-align: center;
}
.t-r {
  text-align: right;
}
.t-r .round_btn {
  margin-left: auto;
}
.w-100 {
  width: 100%;
}
.w_box {
  background: #ffffff;
  padding: 10px 10px;
  border-radius: 5px;
}
.FBAppPage {
  margin-bottom: 120px;
}
.field_box_heading {
  font-weight: 700;
  font-size: 16px;
  color: #4a4a68;
  letter-spacing: 0.04em;
  text-transform: capitalize;
  margin-top: 0;
  margin-bottom: 10px;
}
.img_delete_btn {
  box-shadow: 5px 5px 24px rgb(142 139 139 / 9%);
  border: none;
  background: #fc5252;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffff;
  margin-left: auto;
}
.image_input_upload_label {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  /* margin-bottom: 20px; */
  justify-content: center;
  margin: 0 auto 20px auto;
}
.image_input_upload_label h4 {
  color: #757575;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0;
  margin-top: 0px;
}
#image_input_upload {
  display: none;
}
.image_upload_btn_wrap {
  text-align: center;
}
.blue_btn {
  background: #4b4ded;
  /* box-shadow: inset 0px -1px 0.5px rgb(14 14 44 / 40%); */
  border-radius: 25px;
  padding: 15px 30px;
  font-weight: 600;
  font-size: 12px;
  color: #ffffff;
  text-transform: uppercase;
  align-items: center;
  border: none;
  margin-bottom: 10px;
  letter-spacing: 1px;
  text-align: center;
}
.d-inline {
  display: inline-block;
}

.qty_btn_wrap {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 5px;
}
.after_add_item_wrap {
  display: flex;
  justify-content: space-between;
}
.after_add_item_wrap_btn {
  font-weight: 600;
  font-size: 12px;
  color: #4b4ded;
  text-transform: capitalize;
  border: none;
  background: transparent;
  padding-right: 0;
}
.after_add_item_wrap_label {
  font-weight: 400;
  font-size: 12px;
  color: #4a4a68;
  text-transform: capitalize;
}

/* items bill card */
.item_bill_card_wrap {
  padding: 7px 7px;
  background: #ffffff;
  border-radius: 8px;
}
.item_bill_card_top {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
}
.item_bill_card_name {
  font-weight: 600;
  font-size: 12px;
  color: #4a4a68;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-width: 75%;
  text-transform: capitalize;
}
.item_bill_card_price {
  font-weight: 600;
  font-size: 12px;
  color: #31d0aa;
}

.item_bill_card_details_item {
  display: flex;
  margin-bottom: 4px;
}

.item_bill_card_details_item_label {
  font-weight: 400;
  font-size: 10px;
  color: #8c8ca2;
  min-width: 70px;
}
.item_bill_card_details_item_value {
  font-weight: 400;
  font-size: 10px;
  color: #8c8ca2;
}
.item_bill_card_bottom {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #ecf1f4;
  padding-top: 10px;
}
.item_bill_card_bottom_label {
  font-weight: 600;
  font-size: 12px;
  color: #4a4a68;
}
.item_bill_card_total_price {
  font-weight: 600;
  font-size: 12.7556px;
  color: #31d0aa;
  text-align: right;
}
.item_bill_card_details_btns_wrap {
  display: flex;
}
.item_bill_card_details_btns_wrap button {
  border: none;
  background: transparent;
}
.item_bill_card_details_wrapper {
  display: flex;
  justify-content: space-between;
}
.qty_primary_add_btn {
  font-weight: 600;
  font-size: 16px;
  color: #4b4ded;
  border: none;
  background: transparent;
}
.purchase_discount_wrap {
  display: flex;
  align-items: flex-end;
}
.purchase_discount_label {
  font-weight: 500;
  font-size: 12px;
  color: #4a4a68;
  margin-right: 20px;
  margin-bottom: 10px;
}
.purchase_discount_values {
  display: flex;
  flex: 1;
}
.purchase_discount_values > * {
  flex-basis: 100%;
  text-align: right;
}
.purchase_discount_percnt_value {
  font-weight: 600;
  font-size: 12px;
  color: #4a4a68;
  margin-right: 10px;
  margin-bottom: 10px;
}
.purchase_discount_price_value {
  color: #31d0aa;
  font-weight: 600;
  font-size: 12px;
}
.purchase_bill_details_wrap .item_bill_card_wrap {
  padding: 0px;
  background: transparent;
  border-radius: 0px;
}
.purchase_invoice_item_edit_wrap {
  padding: 10px;
}
.purchase_invoice_item_edit_top {
  display: flex;
  justify-content: space-between;
}
.purchase_invoice_item_edit_top > * {
  max-width: 48%;
  width: 48%;
}
.purchase_invoice_item_edit_qty_wrap {
  display: flex;
  align-items: center;
}
.purchase_invoice_item_edit_qty_value {
  margin-right: 10px;
  font-weight: 400;
  font-size: 12px;
  color: #4a4a68;
}
.purchase_invoice_item_edit_qty_label {
  margin-bottom: 3px;
}
.edit_option_head button {
  float: right;
  border: none;
  background: transparent;
  color: #fff;
}
.purchase_discount_btn_wrap {
  text-align: right;
}
.purchase_invoice_total_wrap .item_bill_card_bottom {
  border-top: none;
  padding-top: 0;
  align-items: center;
}
.purchase_invoice_payment_label {
  font-weight: 400;
  font-size: 12px;
  color: #4a4a68;
  margin-bottom: 5px;
}
.mb-2 {
  margin-bottom: 20px;
}
.invoice_edit_drawer_wrap {
  padding: 10px;
}

/* payment received */
.pay_receive_inv_list_details_wrap {
  padding-left: 0px;
  list-style: none;
  margin-bottom: 10px;
}
.pay_receive_inv_list_details_item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 6px;
}
.pay_receive_inv_list_details_item > * {
  width: 49%;
}
.pay_receive_inv_list_details_num {
  font-weight: 500;
  font-size: 16px;
  color: #0e0e2c;
}
.pay_receive_inv_list_details_total_amount {
  font-weight: 500;
  font-size: 14px;
  color: #868686;
  text-align: right;
}
.pay_receive_inv_list_details_date {
  font-weight: 400;
  font-size: 12px;
  color: #8c8ca2;
}
.pay_receive_inv_list_details_settled {
  font-weight: 500;
  font-size: 12px;
  color: #31d0aa;
  text-align: right;
}
.purchase_invoice_list_drawer_wrap {
  padding: 10px;
  background: #ecf1f4;
}

/* bill */
.bill_edit_btn {
  padding: 4px 10px;
  color: #4b4ded;
  border: 2px solid #ccccff;
  border-radius: 8px;
  background: #fff;
  font-weight: 500;
  font-size: 14px;
  box-shadow: 0 0 1in -0.25in rgb(0 0 0 / 50%);
  float: right;
}
#invoice-POS {
  box-shadow: 0 0 1in -0.25in rgba(0, 0, 0, 0.5);
  padding: 2mm;
  margin: 0 auto;
  /* width: 44mm; */
  background: #fff;
}
.bill_from_address_name {
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
}
.bill_from_address_mobile,
.bill_from_address_details {
  font-size: 10px;
}
.bill_details_top_wrap {
  padding-left: 0;
  list-style: none;
  display: flex;
  font-size: 7px;
  justify-content: space-between;
  text-transform: capitalize;
  background: #eee;
  padding: 5px 5px;
}
.bill_details_top_item:first-child {
  text-align: left;
}
.bill_details_top_item {
  flex-basis: 100%;
  text-align: center;
}
.bill_details_top_item:last-child {
  text-align: right;
}
.bill_to_address_title {
  font-size: 12px;
  font-weight: 500;
  text-transform: capitalize;
}
.bill_to_address_name {
  font-size: 9px;
  text-transform: uppercase;
  font-weight: 500;
}
.bill_to_address_mobile,
.bill_to_address_details {
  font-size: 8px;
}
.bill_thead tr th:first-child {
  text-align: left;
  padding-left: 4px;
}
.bill_thead tr th {
  text-align: right;
  padding: 5px 2px;
  font-size: 8px;
  font-weight: 600;
}
.bill_thead tr th:last-child {
  padding-right: 4px;
}
.bill_tbody tr td:first-child {
  text-align: left;
}
.bill_tbody tr td {
  text-align: right;
  padding: 5px 2px;
  font-size: 8px;
}
.bill_tbody tr td:last-child {
  padding-right: 4px;
}
.bill_tbody tr {
  border-bottom: 1px solid #eee;
}
.bill_tfoot tr td:first-child {
  text-align: left;
  padding-left: 4px;
}
.bill_tfoot tr td {
  text-align: right;
  padding: 5px 2px;
  font-size: 8px;
  font-weight: 600;
  text-transform: capitalize;
}
.bill_tfoot tr td:last-child {
  padding-right: 4px;
}
.bill_bottom_table_wrap {
  padding-left: 0;
  list-style: none;
  width: fit-content;
  width: -moz-fit-content;
  margin-left: auto;
}
.bill_bottom_table_item {
  display: flex;
  font-size: 9px;
  text-transform: capitalize;
}
.bill_bottom_table_item_label {
  text-align: right;
  min-width: 100px;
}
.bill_bottom_table_item_value {
  width: 100px;
  text-align: right;
}
.bill_bottom_table_item:last-child {
  border-top: 2px solid #eee;
  padding: 5px 0;
  font-weight: 600;
}
#invoice-POS ::selection {
  background: #f31544;
  color: #fff;
}
#invoice-POS ::moz-selection {
  background: #f31544;
  color: #fff;
}
#invoice-POS h1 {
  font-size: 1.5em;
  color: #222;
}
/* #invoice-POS h2 {
  font-size: 0.9em;
} */
#invoice-POS h3 {
  font-size: 1.2em;
  font-weight: 300;
  line-height: 2em;
}
#invoice-POS p {
  font-size: 0.7em;
  color: #666;
  line-height: 1.2em;
}
#invoice-POS #top,
#invoice-POS #mid,
#invoice-POS #bot {
  /* Targets all id with 'col-' */
  border-bottom: 1px solid #eee;
}
#invoice-POS #top {
  min-height: 100px;
}
#invoice-POS #mid {
  min-height: 80px;
}
#invoice-POS #bot {
  min-height: 50px;
}
#invoice-POS #top .logo {
  height: 60px;
  width: 60px;
  background: url(http://michaeltruong.ca/images/logo1.png) no-repeat;
  background-size: 60px 60px;
}
#invoice-POS .clientlogo {
  float: left;
  height: 60px;
  width: 60px;
  background: url(http://michaeltruong.ca/images/client.jpg) no-repeat;
  background-size: 60px 60px;
  border-radius: 50px;
}
#invoice-POS .info {
  display: block;
  margin-left: 0;
}
#invoice-POS .title {
  float: right;
}
#invoice-POS .title p {
  text-align: right;
}
#invoice-POS table {
  width: 100%;
  border-collapse: collapse;
}
#invoice-POS .tabletitle {
  font-size: 0.5em;
  background: #eee;
}

/* #invoice-POS .item {
  width: 24mm;
} */
#invoice-POS .itemtext {
  font-size: 0.5em;
}
#invoice-POS #legalcopy {
  margin-top: 5mm;
}
.bill_top_box {
  display: flex;
  justify-content: space-between;
}
.bill_enitiy_type {
  font-size: 11px;
  font-weight: 600;
  text-transform: capitalize;
}
/* profile */

.profile_top {
  display: flex;
  justify-content: space-between;
}
.profile_left {
  display: flex;
  flex: 1;
}
.profile_img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  min-width: 40px;
}
.profile_details {
  margin-left: 10px;
}
.profile_name {
  font-weight: 500;
  font-size: 14px;
  color: #0e0e2c;
  margin-top: 0;
  margin-bottom: 4px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-transform: capitalize;
}
.profile_party_type {
  font-weight: 400;
  font-size: 12px;
  color: #fc5252;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.option_btn {
  border: none;
  background: transparent;
  color: #4a4a68;
  font-size: 20px;
  min-width: 50px;
}
.option_btn svg {
  font-size: 35px;
}
.profile_party_info {
  font-size: 11px;
  display: flex;
  margin-bottom: 3px;
}
.profile_party_info_label {
  font-size: 12px;
  font-weight: 600;
  margin-right: 2px;
}
.item_sub_details_head {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 5px;
}
.invoice_top_box_desc_date {
  margin-right: 5px;
}
