@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap);

:root {
  --clr-primary-200: #0747a6;
  --clr-primary-400: #fa4f55;

  --clr-neutral-100: #fff;
  --clr-neutral-900: #222c2a;

  --ff-primary: "Roboto", sans-serif;
  --ff-accent: "Lato", sans-serif;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  font-family: "Roboto", sans-serif;
  font-family: var(--ff-primary);
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.6;
  background: #f2f2f2;
}

body,
h1,
h2,
h3,
p {
  margin: 0;
}

h1,
h2,
h3 {
  font-family: "Roboto", sans-serif;
  font-family: var(--ff-primary);
  font-weight: 900;
  line-height: 1;
  margin-bottom: 1rem;
}

h2,
h3,
p {
  margin-bottom: 1em;
}
h1 {
  font-size: 18px;
  line-height: 1.3;
}
a {
  color: #0052cc;
  text-decoration: none;
}

a:focus {
  outline: 2px solid #4c9aff;
}

img {
  display: block;
  max-width: 100%;
}

.text-center {
  text-align: center;
}

.page {
  margin-top: 65px;
}

.split {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.split.reverse {
  flex-direction: column-reverse;
}
.alignStart {
  align-items: flex-start;
}
.justifyStart {
  justify-content: flex-start;
}

.activeColor {
  color: green !important;
}
.HomeCompanyAppsWrapper {
  display: flex;
  flex-wrap: wrap;
}

.container {
  margin: 0 auto;
  width: min(90%, 70.5rem);
}
.container--narrow {
  max-width: 34rem;
  margin: 0 auto;
  padding: 0 10px;
}

.bg-light {
  background-color: #0747a6;
  background-color: var(--clr-primary-200);
}

.bg-primary {
  color: #fff;
  color: var(--clr-neutral-100);
  background-color: #fa4f55;
  background-color: var(--clr-primary-400);
}

.bg-dark {
  color: #fff;
  color: var(--clr-neutral-100);
  background-color: #222c2a;
  background-color: var(--clr-neutral-900);
}

.bg-primary h2,
.bg-dark h2 {
  color: #0747a6;
  color: var(--clr-primary-200);
}

.botton {
  padding: 5px 15px;
  background: #fa4f55;
  background: var(--clr-primary-400);
  color: #fff;
  text-decoration: none;
  font-size: 1rem;
  border-radius: 5px;
}

/* section start */
section {
  padding: 15px;
  flex-grow: 1;
}
.section{
  flex-grow: 1;
}
.homeSection {
  padding:0;
}
.section-heading {
  font-size: 30px;
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 3rem;
  color: #023A77;
}
.section-heading span:first-child {
  color: #1b4962;
  display: block;
}
.section-heading span:last-child {
  color: #fa4f55;
  color: var(--clr-primary-400);
  display: block;
}
/* section start */

/* Sidebar start */

.sideBar {
  position: fixed;
  left: 0;
  height: 100%;
  width: 25%;
  display: flex;
  flex-direction: column;
  background: linear-gradient(
    141deg,
    rgba(250, 80, 85, 1) 0%,
    rgba(157, 0, 198, 1) 100%
  );
  padding: 30px;
  justify-content: space-between;
  color: #fff;
}

.sidebarList {
  display: flex;
  flex-direction: column;
  margin-top: 5rem;
}

.nav_button {
  color: #fff;
  font-size: 14px;
  text-decoration: none;
  margin-bottom: 5px;
  padding: 5px 15px;
  border-radius: 4px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.nav_button:hover {
  background: rgb(0 0 0 / 9%);
}

.nav_button.active_menu {
  background: rgb(0 0 0 / 40%);
}

.nav_button_icon {
  display: inline-block;
  margin-right: 10px;
}

.seperator {
  border-bottom: 1px solid #e6e6e6;
  margin: 10px 0;
}

.drawerBotton {
  position: absolute;
  bottom: 50px;
  right: 0;
  background: #fff;
  width: 30px;
  height: 30px;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
  color: #a104c2;
  font-weight: bold;
  padding: 3px 10px;
  cursor: pointer;
}

.helpButton {
  height: 35px;
  width: 35px;
  background: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  margin-bottom: 10px;
}

.sidebarProfileButton {
  height: 35px;
  width: 35px;
  background: #f94f56;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  margin-bottom: 10px;
  color: #ffffff;
  position: relative;
}

.sidebarProfileButton:hover .sidebarProfileSettings {
  display: block;
}

.sidebarProfileSettings {
  display: none;
  position: absolute;
  background: #fff;
  left: 35px;
  width: 150px;
  padding: 10px;
  font-size: 14px;
  flex-direction: column;
}

.sidebarProfileSettings a {
  display: block;
}

/* Sidebar end */
/* Login Page  */
.fullPage {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.fullPageImage {
  flex: 5 1;
  height: 100%;
  display: flex;
  align-items: flex-start;
  padding: 2rem;
  flex-direction: column;
}

.fullPageform {
  flex: 4 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.loginFormWrapper {
  padding: 30px;
  min-width: 300px;
}

.loginLightMessage {
  color: rgb(94, 108, 132);
  text-align: center;
  font-size: 14px;
}

.loginDarkMessage {
  font-weight: bold;
  text-align: center;
  font-size: 14px;
  color: rgb(94, 108, 132);
  margin-bottom: 30px;
}

.loginLogo {
  max-width: 200px;
  margin: 0 auto;
  margin-bottom: 30px;
}

.loginPageLinks {
  font-size: 14px;
}

.loginHeading {
  margin-bottom: 2rem;
}

.loginBanner {
  max-width: 500px;
  margin-top: 5rem;
}

/* Welcome page  */
.SignupWelcomePage {
  margin: 0 auto;
  padding: 15px;
  text-align: center;
  max-width: 500px;
}

.SignupWelcomePage img {
  max-width: 200px;
  margin: 0 auto;
}

/* Notification list  */
.notifList {
  display: flex;
  margin: 30px 0;
  flex-wrap: wrap;
  flex-direction: column;
}

.notifList > div {
  padding: 5px 10px;
  margin: 5px 0;
  background: #fff;
  border-radius: 8px;
  font-size: 14px;
}

/* List start  */
.List {
  display: flex;
  margin: 15px 0;
  flex-wrap: wrap;
}

.listHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.formList {
  display: flex;
  margin: 15px 0;
  flex-wrap: wrap;
  justify-content: flex-end;
}

/* List end  */
.selectBox {
  margin-bottom: 10px !important;
}

/* Card  start*/
.detailHeading {
  font-size: 12px;
  font-weight: bold;
}

.detailValue {
  font-size: 14px;
}

.notificationCard {
  color: #222c2a;
}

.notificationHeading {
  font-weight: bold;
}

/* Card end */

/* Details pages */
.detailsItem {
  display: flex;
  padding-bottom: 0.5rem;
  font-size: 13px;
}

.detailsKey {
  font-weight: bold;
  text-transform: capitalize;
  min-width: 100px;
}

/* Details pages */

.TableHeader {
  font-weight: bold !important;
}

.features {
  padding: 3em 0;
}

.buttons {
  margin-left: 1em;
}

.hero {
}

.hero-body {
  display: flex;
}

.hero-links {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.content {
  max-width: 250px;
  min-width: 250px;
  padding: 10px;
  margin: 10px;
  border: 1px solid #f2f2f2;
  background: #f2f2f2;
}

/* Forms */
.formSeperator {
  margin-top: 50px;
}

.form-item-wrapper {
  margin-bottom: 10px;
}

.formContainer {
  padding: 15px;
}

.searchUserBox {
  margin-top: 30px;
  padding: 15px;
  border: 1px solid #ccc;
}

.searchUserFormWrap {
  display: flex;
  justify-content: space-between;
}

.successText {
  color: green;
  font-size: 14px;
}

.failureText {
  color: red;
  font-size: 14px;
}

.formConfirmationMsg {
  margin: 10px 0;
  font-size: 16px;
}

.bold {
  font-weight: bold !important;
}

.verticalSpacer15 {
  width: 15px;
  display: inline-block;
}

.FormInput {
  min-width: 320px !important;
}

.detailsDrawer {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: calc(100% - 230px);
  padding: 75px 20px;
  background: #fff;
  z-index: 99;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  overflow-y: scroll;
}

.detailsCloseBtn {
  position: absolute !important;
  right: 0;
  top: 0;
}

.DataGridHeading {
  font-weight: bold;
}

.link {
  color: #0645ad !important;
}

.help.is-danger {
  color: red;
}

.MuiDataGrid-columnHeaderTitle {
  font-weight: bold !important;
}

.paper {
  padding: 15px;
}

.collapsed {
  width: 50%;
}

/* Config Page */

.configPageWrapper {
  display: flex;
}

/* IBC specific styles */
.UserHeader {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  height: 50px;
  background: #ddd;
  display: flex;
  align-items: center;
  padding: 10px;
  z-index: 9;
}

.businessServicesWrapper {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 15px;
}

.UserPageWrapper {
  margin-top: 50px;
  margin-bottom: 50px;
  width: calc(100% - 240px);
  margin-left: auto;
}

.UserFooterItem {
  flex: 1 1;
  text-align: center;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.UserFooterItem img {
  width: 40px;
  margin: 0 auto;
}

/* Ticket app  */
.TicketdetailsDrawer {
  position: fixed;
  top: 50px;
  right: 50px;
  bottom: 50px;
  left: 50px;
  padding: 27px 20px;
  background: #fff;
  display: grid;
  box-shadow: 0px 0px 0px 9999px rgb(0 0 0 / 50%);
  grid-template-columns: 60% 35%;
  grid-gap: 30px;
  grid-gap: 30px;
  gap: 30px;
  z-index: 99;
}

.ticketDetailsWrapper {
  margin-right: 2%;
  border: 1px solid #c7c7c7;
  padding: 15px;
  max-height: 100%;
  overflow: scroll;
  margin-top: 25px;
}

.ticketsDocumentWrapper {
  padding-top: 15px;
}

.ticketsCommentWrapper {
  width: 80%;
  padding-top: 30px;
}

.ticketDetailsCommentItem {
  background: rgb(241, 241, 241);
  padding: 5px 10px;
  margin-bottom: 5px;
  border-left: 3px solid #0747a6;
  border-radius: 20px;
}

.ticketDetailsCommentHeader {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgb(240, 240, 240);
}

.ticketDetailsCommentUser {
  color: #0052cc;
}

.ticketDetailsCommentMetaWrap {
  font-size: 0.7rem;
}

.ticketAddBtn {
  position: absolute !important;
  top: 84px;
  right: 30px;
}

.ticketDetailsDocumentItem {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #cfcfcf;
  align-items: center;
  padding: 5px 0;
  margin-bottom: 5px;
}

.ticketDetailsSaveBtn {
  position: absolute;
  left: 10px;
  bottom: 10px;
}

.ticketDetailsCommentFormWrap {
  padding: 15px 0;
}

.ticketDetailsCommentFormWrap .input {
  margin-bottom: 10px;
}

#TicketTitle {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 15px;
}

.scrolable {
  overflow-y: scroll;
  padding-right: 30px;
  height: calc(100% - 30px);
}

.Document-form-item-wrapper {
  padding: 15px 10px;
  background: #efefef;
}
.TicketsPage {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 15px;
  gap: 15px;
  margin-top: 30px;
}
.TicketTablePaper {
  padding: 10px;
}
.ticketTagHeadingWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ticketTagAddButtonWrap {
  display: flex;
  justify-content: center;
  align-items: center;
}
.ticketsDocumentItem {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  border: 1px solid #dedede;
  padding: 5px;
  text-align: center;
}
.ticketsDocumentItem .pg-viewer-wrapper {
  height: 150px;
  width: 120px;
  overflow: hidden;
  margin: 0 auto;
  padding-bottom: 10px;
  border-bottom: 1px solid #dedede;
}
.projectListCardName,
.sprintListCardName {
  padding: 5px 0;
}
.ticketsDocumentItemWrapper {
  display: flex;
  grid-gap: 15px;
  gap: 15px;
  flex-wrap: wrap;
  margin-bottom: 15px;
}
.ticketsDocumentItemName {
  padding-bottom: 10px;
  font-size: 10px;
}
.ticketsDocumentItem .pg-viewer-wrapper .pg-viewer {
  width: 100%;
}
.ticketsDocumentItemBtnWrapper {
  display: flex;
}
.ticketCreateProjectForm {
  display: flex;
  margin-bottom: 10px;
  padding: 10px;
  grid-gap: 6px;
  gap: 6px;
}
.ticketProjectSearch {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.scrolable {
  overflow-y: scroll;
  padding-right: 30px;
  margin-bottom: 50px;
}

.UserOrgConfirmationPageWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 10px;
}

.UserOrgConfirmationPageWrapper img {
  width: 200px;
}

.redirect_message {
  font-size: 13px;
  margin-top: 15px;
}

.PaymentPopUpWrapper {
  text-align: center;
  max-width: 300px;
  margin: 0 auto;
  padding-top: 30px;
}

.PaymentPopUpIcon {
  padding: 15px;
  background: #f94f55b6;
  width: 100px;
  margin: 0 auto;
  margin-bottom: 15px;
  border-radius: 25px;
}

.PaymentPopUpTitle {
  font-weight: bold;
  font-size: 20px;
  color: rgb(22, 22, 22);
  margin-bottom: 10px;
}

.PaymentPopUpDes {
  font-size: 12px;
  margin-bottom: 30px;
  line-height: 1.3;
}

.PaymentPopUpEyebrow {
  background: #d1e9f8;
  width: -webkit-max-content;
  width: max-content;
  color: #59b1e6;
  padding: 3px 10px;
  border-radius: 25px;
  font-size: 12px;
  margin: 0 auto;
  margin-bottom: 10px;
}

.PaymentPopUpWrapper .price {
  color: #0747a6;
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 30px;
}

.PaymentPopUpTerms {
  font-size: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.PaymentPopUpWrapper button {
  padding: 10px 30px;
  border-radius: 6px;
  background: #0747a6;
  border: unset;
  color: #fff;
  font-weight: bold;
  margin-top: 15px;
  cursor: pointer;
}

/* Notifications */

.NotificationData {
  font-size: 14px;
  margin-bottom: 15px;
}

.NotificationDetail {
  font-size: 16px;
}

.NotificationLabel {
  font-weight: bold;
}

.NotificationStatus {
  margin-bottom: 15px;
  color: #00c853;
}

.TableHeading .MuiTableCell-head {
  font-weight: bold;
}

.PostListFeaturedImage {
  width: 50px;
  height: 50px;
}

/* Support/help page  */
.HelpPageWrapper {
  margin: 30px;
  max-width: 80%;
}

.HelpCategoryWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  grid-gap: 30px;
  gap: 30px;
  padding: 30px;
  background: #f2f2f2;
}

.HelpCategoryItem {
  min-width: 280px;
  border-radius: 3px;
  box-shadow: 0 0 1px 0 rgb(23 43 77 / 24%);
  padding: 15px;
  background: #fff;
  display: flex;
  grid-gap: 30px;
  gap: 30px;
  max-width: 49.5%;
  cursor: pointer;
}

.HelpCategoryItem:hover {
  background-color: #e8f0fe;
}

.HelpCategoryItem img {
  width: 100px;
}

.HelpCategoryItemTitle {
  font-size: 20px;
  font-weight: bold;
  color: #333;
  margin-bottom: 15px;
  line-height: 1;
}

.HelpCategoryItemText {
  font-size: 14px;
  color: #333;
  font-weight: 100;
}

/* Faq page  */
.FaqPageWrapper {
  margin: 30px;
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.rdw-editor-wrapper {
  box-sizing: content-box;
  border: 1px solid #d1d1d1;
  min-height: 300px;
  box-sizing: content-box;
  width: 100%;
  height: 100%;
  padding: 10px;
}

.userEditorWrapper {
  border: 0;
  padding: 0;
}
/* Govt schemes */
.govtSchemeCategoryWrapper {
  padding: 10px;
}
.categoryLink:hover {
  color: #0052cc;
  font-weight: bold;
}
/* Course  */

.CourseGrid {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 30px;
  gap: 30px;
}
.CourseDetailsWrapper {
  background: #eaf5ff;
  padding: 15px;
  margin-top: 15px;
  font-size: 14px;
  margin-bottom: 30px;
}
.CourseModuleWrapper{
  background-color: unset !important;
  box-shadow: unset !important;
  margin-top: 15px;
}

.CourseModuleTumbnail {
  display: grid;
  grid-template-columns: 100px 1fr;
  grid-gap: 10px;
  gap: 10px;
  padding: 10px;
  font-size: 14px;
  border-bottom: 1px solid #e4e4e4;
  cursor: pointer;
}
.CourseModuleTumbnail.Active {
  background-color: #e8f0fe;
}
.CourseModuleTitle {
  font-weight: bold;
  margin-top: 10px;
}
.CourseModuleTitleTestBtn{
  margin-top: 30px;
}
.CourseWrapperflex {
  display: flex;
  grid-gap: 15px;
  gap: 15px;
  flex-wrap: wrap;
}
.courseLineItemAdmin{
  display: flex;
  grid-gap: 15px;
  gap: 15px;
  margin-bottom: 30px;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  align-items: center;
  padding: 10px;
}
.courseLineItemAdmin img{
  width: 200px;
}
.courseLineItemAdminBtnWrap{
  display: flex;
}
.courseAddChaptersForm{
  padding: 20px 0;
}
.visionMissionItem{
  text-align: left;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  padding: 15px;
  overflow: hidden;
  min-height: 180px;
  position: relative;
  margin-bottom: 15px;
}
.visionMissionItem h2{
  font-size: 30px;
  margin-bottom: 10px;
  margin-top: 15px;
}
.visionMissionItem p{
  font-size: 16px;
}
.visionMissionItem img{
  position: relative;
  right: 0;
  width: 50%;
}
.video_player {
  width: calc(100% + 30px);
  margin-left: -15px;
  margin-right: -15px;
}
.correctAnswerLabel {
  padding-top: 10px;
  display: inline-block;
  font-size: 11px;
}
.QAoptions{
  -webkit-column-count: 2;
          column-count: 2;
  padding: 0;
  padding-bottom: 30px;
}
.CourseQaAnswer {
  color: red;
}
.CourseQaAnswer.Correct{
  color: green;
}
.QAbox{
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  margin-top: 10px;
}
.QApageSection{
  margin: 30px 0;
}
.PageBannerWrapper {
  grid-gap: 15px;
  gap: 15px;
  margin-bottom: 15px;
  margin-top: 15px;
}

.PageBanner {
  background: #fff;
  border-radius: 15px;
  padding: 20px;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}

.PageBanner img {
  display: none;
}

.PageBanner h1 {
  font-size: 18px;
  margin-bottom: 5px;
}

.PageBanner p {
  font-size: 12px;
}
.Homepage {
  background: #fff;
}
.homePageBanner {
  display: flex;
  background: #fff;
  padding: 30px 5px;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  background-size: cover;
  margin-top: 65px;
  color: rgb(0, 0, 0);
  background-repeat: no-repeat;
}
.homePageBanner h1 {
  font-size: 30px;
  line-height: 1;
  color: #023A77;
}

.homePageBanner p {
  color: #023A77;
  font-size: 14px;
}
.BannerWithGradient {
  background: linear-gradient(91.54deg, #ddd4e7 35.77%, #ce9ca6 72.08%);
  box-shadow: 0px 0px 12px 4px rgba(0, 0, 0, 0.04);
  border-radius: 20px !important;
}
.HomeCompanyAppsSection {
  padding: 15px 0;
}
.HomeServicesWrapper {
  grid-gap: 15px;
  gap: 15px;
  justify-content: space-between;
  display: flex;
  flex-wrap: wrap;
}

.HomeServicesCard {
  padding: 15px;
  width: 47%;
  cursor: pointer;
}
.HomeServicesCard h2 {
  margin-bottom: 10px;
}

.HomeServicesCard:hover {
  background-color: #e8f0fe;
}

.HomeServicesCard img {
  max-width: 80px;
  margin-bottom: 10px;
}
.homeSection{
  padding: 3rem 0;
}
.homeEnquireFormSection{
  background: #ebf5ff;
  padding: 0;
}
.homeEnquireForm{
  padding: 2rem 30px;
}
.requestSuccessWrapper img {
  max-width: 150px;
  margin: 0 auto;
}

.requestSuccessWrapper h1 {
  text-align: center;
  font-size: 25px;
}

.requestSuccessWrapper p {
  text-align: center;
}

.requestSuccessDetailsWrapper {
  margin-top: 30px;
  text-align: center;
}

.requestSuccessDetailsWrapper .amount {
  padding: 10px 30px;
  border: 1px solid #c7c7c7;
  display: inline-block;
  border-radius: 6px;
}

.requestSuccessDetailsWrapper .amount .lable {
  font-size: 10px;
  color: #cbcbcb;
}

.requestSuccessDetailsWrapper .amount .figure {
  font-size: 16px;
  font-weight: bold;
}

.HomeCompanyAppsWrapper {
  margin-bottom: 15px;
}

.companyProfileBannerWrapper {
  margin-bottom: 15px;
}

.HomeCompanyAppsItem {
  padding: 10px;
  text-align: center;
  width: 120px;
  cursor: pointer;
}

.HomeCompanyAppsItem:hover {
  background-color: #e8f0fe;
}

.HomeCompanyAppsItem img {
  max-width: 35px;
  margin: 0 auto;
}

.HomeCompanyProfileCard {
  min-width: 280px;
  justify-content: space-between;
  grid-gap: 30px;
  gap: 30px;
  font-size: 12px;
  font-weight: bold;
  display: flex;
  color: #fff !important;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  cursor: pointer;
  padding: 20px;
  border-radius: 20px !important;
}

.HomeCompanyProfileCard img {
  max-width: 60px;
  align-self: flex-end;
}

.HomeCompanyProfileCard .CompanyName {
  text-transform: capitalize;
  font-weight: bold;
  font-size: 18px;
}
.HomeCompanyProfileCard .OwnerName {
  font-weight: 400;
  margin-bottom: 30px;
}
.HomeCompanyProfileCard .AddressTitle {
  font-weight: bold;
  font-size: 14px;
}
.HomeCompanyProfileCard .Address {
  font-weight: 400;
}

.companyProfileBanner {
  display: flex;
  align-items: center;
}

.ProfileImageWrap {
  min-width: 175px;
  text-align: center;
  font-weight: bold;
  font-size: 15px;
}

.companyProfileBanner img {
  max-width: 80px;
  margin: 0 auto;
}

.companyProfileBanner .ProfileBasicsWrap {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  padding: 10px;
}

.companyProfileBanner .ProfileBasicsWrap > div {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 20px 0;
}

.ProfileBasicsItem span:first-child {
  color: #a7a7a7;
  padding-right: 10px;
}

.ProfileBasicsItem span:last-child {
  font-weight: bold;
  color: #333;
}
.homeServicesCardWrapper {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 10px;
  gap: 10px;
  margin-bottom: 30px;
}
.homeServicesCard {
  background: #fff;
  width: 48%;
  padding: 10px;
  box-shadow: 0px 0px 5px 5px rgb(26 26 26 / 6%);
  border-radius: 15px;
  text-align: center;
  min-width: 135px;
}
.homeServicesCardHeading {
  font-size: 12px;
  font-weight: bold;
}
.homeServicesCard img {
  max-width: 100px;
  margin: 0 auto;
}

.ProfileBasicsWrap {
  padding: 15px;
}
.postImage {
  width: 100%;
}

.ToolsCard {
  background: #fff;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  padding: 10px;
}
.CategoryHeading {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 15px;
}
.categoryLink {
  display: block;
  padding: 10px 0;
  color: #000;
  border-top: 1px solid #d6d6d6;
  font-size: 14px;
}

/* Investor app  */
.invRequestDetailsDrawer {
  position: fixed;
  left: 10%;
  right: 10%;
  top: 15%;
  bottom: 10%;
  background: #fff;
  padding: 2rem 1rem;
  box-shadow: 0px 0px 0px 9999px rgb(0 0 0 / 50%);
  overflow: scroll;
}
.InvReqWorkQueueCardWrapper {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 10px;
  gap: 10px;
}
.InvReqWorkQueueCard {
  min-width: 250px;
  max-width: 300px;
  padding: 10px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
}
.InvReqWorkQueueCardTitle {
  font-weight: bold;
  font-size: 20px;
  padding-bottom: 10px;
}
.InvReqWorkQueueCardDetails > span {
  font-weight: bold;
}
.invReqDetailsLabel {
  font-weight: bold;
}
.invReqDetailsData {
  font-size: 15px;
}


@media (min-width: 40em) {
  h1 {
    font-size: 24px;
  }
  .split {
    flex-direction: row;
  }
  .split.reverse {
    flex-direction: row;
  }
  .split > * {
    flex-basis: 100%;
  }

  .split > * + * {
    margin-left: 2em;
  }
  .page {
    display: flex;
    margin-top: 65px;
  }
  .PageBanner {
    display: flex;
  }
  .PageBannerWrapper {
    margin-bottom: 0 !important;
  }
  .HomeServicesWrapper {
    flex-direction: row;
    justify-content: flex-start;
  }
  .CourseGrid {
    display: grid;
    grid-template-columns: 1fr 300px !important;
    grid-gap: 30px;
    grid-gap: 30px;
    gap: 30px;
    padding-top: 30px;
  }
  .homePageBanner {
    display: flex;
    padding: 20px;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
    background-position: 100% 100%;
    background-size: cover;
    margin-top: 65px;
    height: 80vh;
    background-repeat: no-repeat;
    color: #222c2a !important;
    color: var(--clr-neutral-900) !important;
  }
  .HomeServicesCard{
    padding: 10px;
    min-width: 138px;
    max-width: 200px;
    cursor: pointer;
    
  }
  .PageBanner img {
    display: block;
    max-width: 220px;
  }

  .visionMissionItem{
    text-align: left;
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
    padding: 15px;
    overflow: hidden;
    min-height: 180px;
    position: relative;
  }
  .visionMissionItem h2{
    font-size: 30px;
  }
  .visionMissionItem p{
    font-size: 16px;
  }
  .visionMissionItem img{
    position: absolute;
    right: 0;
  width: 50%;
}
  .video_player {
    width: 100%;
    min-width: 798px;
    max-height: 450px;
  }
}

.clr_green {
  color: #31d0aa;
}
.clr_red {
  color: #fc5252;
}
.clr_violet {
  color: #4b4ded;
}
.clr_grey {
  color: #8c8ca2;
}
.AppHomePage {
  margin-top: 70px;
}
.mt {
  margin-top: 10px;
}
.mb {
  margin-bottom: 10px;
}
.float-r {
  float: right;
}
.pt {
  padding-top: 10px;
}
.pt-20 {
  padding-top: 20px;
}
.form_error {
  background: #ff3c3c;
  padding: 6px 10px;
  margin-bottom: 15px;
  border-radius: 4px;
  color: #fff;
  font-size: 10px;
  transition: 0.3s ease-in-out;
}
.primary_title {
  font-weight: 500;
  font-size: 16px;
  color: #4a4a68;
  margin-bottom: 10px;
}
.edit_option_btn {
  border: none;
  background: transparent;
}
.edit_option_btn_drawer .MuiDrawer-paper {
  border-radius: 8px 8px 0px 0px;
}
.edit_option_head {
  background: #9d00c6;
  padding: 12px 17px 12px 17px;
  font-weight: 500;
  font-size: 18px;
  color: #ffffff;
  text-transform: capitalize;
}
.edit_option_tools_wrap {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding-left: 0;
  padding: 15px 25px;
  margin: 0;
}
.edit_option_tools_item {
  width: 33%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #8c8ca2;
  margin-bottom: 10px;
}
.edit_option_tools_item_text {
  font-weight: 400;
  font-size: 12px;
  text-transform: capitalize;
  margin-top: 4px;
}
.AppLabel {
  font-weight: 400;
  font-size: 12px;
  color: #8c8ca2;
  text-align: center;
  text-transform: capitalize;
}
.AppPage {
  margin-top: 15px;
  margin-bottom: 70px;
}
.AppHomePage {
  margin-top: 70px;
}
/* home */
.list_pages_wrap {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 10px 0 0 0;
  margin: 0;
}
.list_pages_item {
  width: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #8c8ca2;
}
.accounting_status_report_wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  list-style: none;
  background: #fff;
  border-radius: 5px;
  padding: 15px 10px;
}
.accounting_status_report_item {
  width: 31%;
  background: #ecf1f4;
  box-shadow: 0px 1px 2px rgba(255, 255, 255, 0.15),
    1px 1px 0px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}
.accounting_status_report_item a {
  padding: 10px 14px;
  width: 100%;
  display: block;
}
.accounting_status_main {
  font-weight: 700;
  font-size: 12px;
  color: #0e0e2c;
  margin-bottom: 3px;
  text-transform: capitalize;
}
.accounting_status_sub {
  font-weight: 400;
  font-size: 12px;
}
/* transaction card */
.transaction_card_wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  border-radius: 6px;
  padding: 5px 10px;
}
.transaction_card_details_wrap {
  display: flex;
  flex-direction: column;
  max-width: 70%;
}
.transaction_card_title {
  font-weight: 500;
  font-size: 14px;
  color: #0e0e2c;
  margin-bottom: 3px;
  text-transform: capitalize;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.transaction_card_order {
  font-weight: 400;
  font-size: 14px;
  color: #4a4a68;
  margin-bottom: 2px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.transaction_card_date {
  font-weight: 400;
  font-size: 12px;
  color: #8c8ca2;
}
.transaction_card_amount {
  font-weight: 500;
  font-size: 12px;
}
/* parties card */
.parties_card_wrap {
  background: #ffffff;
  border-radius: 6px;
  padding: 10px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.parties_card_details {
  display: flex;
  flex-direction: column;
}
.parties_card_name {
  font-weight: 500;
  font-size: 16px;
  color: #0e0e2c;
  margin-bottom: 2px;
  text-transform: capitalize;
}
.parties_card_label {
  font-weight: 400;
  font-size: 12px;
  color: #8c8ca2;
}
.parties_card_amount {
  font-weight: 500;
  font-size: 12px;
}
/* item card */
.item_card_wrap {
  display: flex;
  padding: 7px 7px;
  background: #ffffff;
  /* border-radius: 8px; */
  border-bottom: 2px solid #cccccc;
}
.item_card_wrap:last-child {
  border-bottom: none;
}
.item_card_img {
  /* width: 109px; */
  height: 80px;
  border-radius: 8px;
}
.item_card_img img {
  width: 100%;
  height: 100%;
}
.item_card_content_wrap {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  flex: 1 1;
  justify-content: space-between;
}
.item_card_name {
  font-weight: 500;
  font-size: 16px;
  color: #0e0e2c;
  text-transform: capitalize;
}
.item_card_content_child {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.item_card_details_item {
  font-weight: 500;
  font-size: 12px;
  color: #0e0e2c;

  margin-bottom: 2px;
}
.item_card_details_item_value {
  font-weight: 400;
  color: #4a4a68;
  margin-left: 4px;
  display: inline-block;
}
.item_card_details_item:last-child {
  margin-bottom: 0;
}
.item_card_delete_wrap svg {
  color: #4a4a68;
}
/* ------ */
.round_btn {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  color: #fff;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bg_blue {
  background: #4b4ded;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1), 0px 4px 8px rgba(0, 0, 0, 0.08),
    0px 1px 12px rgba(0, 0, 0, 0.04);
}
.bg_light_green {
  background: #31d0aa;
}
.btn {
  font-weight: 700;
  font-size: 16px;
  text-align: center;
  color: #ffffff;
  box-shadow: 0px 6px 2px -4px rgba(14, 14, 44, 0.1);
  border-radius: 43px;
  border: none;
  padding: 8px 10px;
  text-transform: uppercase;
}
.create_btns_wrap {
  display: flex;
}
.input_icon {
  font-weight: 700;
  font-size: 17px;
  color: #4a4a68;
}
.AppFloatingButtonWrapper {
  padding: 10px;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 60px;
  z-index: 99;
}
.create_drawer_top {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}
.create_option_drawer_child {
  padding: 10px;
}
.create_option_drawer_items_wrapper {
  padding: 0 20px;
}
.option_head {
  font-weight: 500;
  font-size: 15.498px;
  color: #0e0e2c;
}
.create_option_drawer_items_wrapper .edit_option_tools_wrap {
  padding: 15px 0;
  grid-gap: 1%;
  gap: 1%;
}
.create_option_drawer_items_wrapper .edit_option_tools_item {
  width: 24%;
}
.create_option_drawer_items_wrapper .AppLabel {
  font-size: 10px;
  text-transform: capitalize;
}
/* create invoice */
.invoice_top_box {
  display: flex;
  justify-content: space-between;
  background: #ffffff;
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 15px;
}
.invoice_top_box_details {
  display: flex;
  flex-direction: column;
  max-width: 90%;
}
.invoice_top_box_title {
  font-weight: 500;
  font-size: 16px;
  color: #0e0e2c;
  margin-bottom: 5px;
}
.invoice_top_box_desc {
  font-weight: 400;
  font-size: 12px;
  color: #8c8ca2;
}
.invoice_top_box_edit svg {
  color: #4a4a68;
}
.toggle_btns_wrap {
  display: flex;
  justify-content: space-between;
}

.field_split_wrap {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.field_split_wrap > * {
  min-width: 49%;
}
.pt-2 {
  padding-top: 20px;
}
.t-c {
  text-align: center;
}
.t-r {
  text-align: right;
}
.t-r .round_btn {
  margin-left: auto;
}
.w-100 {
  width: 100%;
}
.w_box {
  background: #ffffff;
  padding: 10px 10px;
  border-radius: 5px;
}
.FBAppPage {
  margin-bottom: 120px;
}
.field_box_heading {
  font-weight: 700;
  font-size: 16px;
  color: #4a4a68;
  letter-spacing: 0.04em;
  text-transform: capitalize;
  margin-top: 0;
  margin-bottom: 10px;
}
.img_delete_btn {
  box-shadow: 5px 5px 24px rgb(142 139 139 / 9%);
  border: none;
  background: #fc5252;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffff;
  margin-left: auto;
}
.image_input_upload_label {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  /* margin-bottom: 20px; */
  justify-content: center;
  margin: 0 auto 20px auto;
}
.image_input_upload_label h4 {
  color: #757575;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0;
  margin-top: 0px;
}
#image_input_upload {
  display: none;
}
.image_upload_btn_wrap {
  text-align: center;
}
.blue_btn {
  background: #4b4ded;
  /* box-shadow: inset 0px -1px 0.5px rgb(14 14 44 / 40%); */
  border-radius: 25px;
  padding: 15px 30px;
  font-weight: 600;
  font-size: 12px;
  color: #ffffff;
  text-transform: uppercase;
  align-items: center;
  border: none;
  margin-bottom: 10px;
  letter-spacing: 1px;
  text-align: center;
}
.d-inline {
  display: inline-block;
}

.qty_btn_wrap {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 5px;
}
.after_add_item_wrap {
  display: flex;
  justify-content: space-between;
}
.after_add_item_wrap_btn {
  font-weight: 600;
  font-size: 12px;
  color: #4b4ded;
  text-transform: capitalize;
  border: none;
  background: transparent;
  padding-right: 0;
}
.after_add_item_wrap_label {
  font-weight: 400;
  font-size: 12px;
  color: #4a4a68;
  text-transform: capitalize;
}

/* items bill card */
.item_bill_card_wrap {
  padding: 7px 7px;
  background: #ffffff;
  border-radius: 8px;
}
.item_bill_card_top {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
}
.item_bill_card_name {
  font-weight: 600;
  font-size: 12px;
  color: #4a4a68;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-width: 75%;
  text-transform: capitalize;
}
.item_bill_card_price {
  font-weight: 600;
  font-size: 12px;
  color: #31d0aa;
}

.item_bill_card_details_item {
  display: flex;
  margin-bottom: 4px;
}

.item_bill_card_details_item_label {
  font-weight: 400;
  font-size: 10px;
  color: #8c8ca2;
  min-width: 70px;
}
.item_bill_card_details_item_value {
  font-weight: 400;
  font-size: 10px;
  color: #8c8ca2;
}
.item_bill_card_bottom {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #ecf1f4;
  padding-top: 10px;
}
.item_bill_card_bottom_label {
  font-weight: 600;
  font-size: 12px;
  color: #4a4a68;
}
.item_bill_card_total_price {
  font-weight: 600;
  font-size: 12.7556px;
  color: #31d0aa;
  text-align: right;
}
.item_bill_card_details_btns_wrap {
  display: flex;
}
.item_bill_card_details_btns_wrap button {
  border: none;
  background: transparent;
}
.item_bill_card_details_wrapper {
  display: flex;
  justify-content: space-between;
}
.qty_primary_add_btn {
  font-weight: 600;
  font-size: 16px;
  color: #4b4ded;
  border: none;
  background: transparent;
}
.purchase_discount_wrap {
  display: flex;
  align-items: flex-end;
}
.purchase_discount_label {
  font-weight: 500;
  font-size: 12px;
  color: #4a4a68;
  margin-right: 20px;
  margin-bottom: 10px;
}
.purchase_discount_values {
  display: flex;
  flex: 1 1;
}
.purchase_discount_values > * {
  flex-basis: 100%;
  text-align: right;
}
.purchase_discount_percnt_value {
  font-weight: 600;
  font-size: 12px;
  color: #4a4a68;
  margin-right: 10px;
  margin-bottom: 10px;
}
.purchase_discount_price_value {
  color: #31d0aa;
  font-weight: 600;
  font-size: 12px;
}
.purchase_bill_details_wrap .item_bill_card_wrap {
  padding: 0px;
  background: transparent;
  border-radius: 0px;
}
.purchase_invoice_item_edit_wrap {
  padding: 10px;
}
.purchase_invoice_item_edit_top {
  display: flex;
  justify-content: space-between;
}
.purchase_invoice_item_edit_top > * {
  max-width: 48%;
  width: 48%;
}
.purchase_invoice_item_edit_qty_wrap {
  display: flex;
  align-items: center;
}
.purchase_invoice_item_edit_qty_value {
  margin-right: 10px;
  font-weight: 400;
  font-size: 12px;
  color: #4a4a68;
}
.purchase_invoice_item_edit_qty_label {
  margin-bottom: 3px;
}
.edit_option_head button {
  float: right;
  border: none;
  background: transparent;
  color: #fff;
}
.purchase_discount_btn_wrap {
  text-align: right;
}
.purchase_invoice_total_wrap .item_bill_card_bottom {
  border-top: none;
  padding-top: 0;
  align-items: center;
}
.purchase_invoice_payment_label {
  font-weight: 400;
  font-size: 12px;
  color: #4a4a68;
  margin-bottom: 5px;
}
.mb-2 {
  margin-bottom: 20px;
}
.invoice_edit_drawer_wrap {
  padding: 10px;
}

/* payment received */
.pay_receive_inv_list_details_wrap {
  padding-left: 0px;
  list-style: none;
  margin-bottom: 10px;
}
.pay_receive_inv_list_details_item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 6px;
}
.pay_receive_inv_list_details_item > * {
  width: 49%;
}
.pay_receive_inv_list_details_num {
  font-weight: 500;
  font-size: 16px;
  color: #0e0e2c;
}
.pay_receive_inv_list_details_total_amount {
  font-weight: 500;
  font-size: 14px;
  color: #868686;
  text-align: right;
}
.pay_receive_inv_list_details_date {
  font-weight: 400;
  font-size: 12px;
  color: #8c8ca2;
}
.pay_receive_inv_list_details_settled {
  font-weight: 500;
  font-size: 12px;
  color: #31d0aa;
  text-align: right;
}
.purchase_invoice_list_drawer_wrap {
  padding: 10px;
  background: #ecf1f4;
}

/* bill */
.bill_edit_btn {
  padding: 4px 10px;
  color: #4b4ded;
  border: 2px solid #ccccff;
  border-radius: 8px;
  background: #fff;
  font-weight: 500;
  font-size: 14px;
  box-shadow: 0 0 1in -0.25in rgb(0 0 0 / 50%);
  float: right;
}
#invoice-POS {
  box-shadow: 0 0 1in -0.25in rgba(0, 0, 0, 0.5);
  padding: 2mm;
  margin: 0 auto;
  /* width: 44mm; */
  background: #fff;
}
.bill_from_address_name {
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
}
.bill_from_address_mobile,
.bill_from_address_details {
  font-size: 10px;
}
.bill_details_top_wrap {
  padding-left: 0;
  list-style: none;
  display: flex;
  font-size: 7px;
  justify-content: space-between;
  text-transform: capitalize;
  background: #eee;
  padding: 5px 5px;
}
.bill_details_top_item:first-child {
  text-align: left;
}
.bill_details_top_item {
  flex-basis: 100%;
  text-align: center;
}
.bill_details_top_item:last-child {
  text-align: right;
}
.bill_to_address_title {
  font-size: 12px;
  font-weight: 500;
  text-transform: capitalize;
}
.bill_to_address_name {
  font-size: 9px;
  text-transform: uppercase;
  font-weight: 500;
}
.bill_to_address_mobile,
.bill_to_address_details {
  font-size: 8px;
}
.bill_thead tr th:first-child {
  text-align: left;
  padding-left: 4px;
}
.bill_thead tr th {
  text-align: right;
  padding: 5px 2px;
  font-size: 8px;
  font-weight: 600;
}
.bill_thead tr th:last-child {
  padding-right: 4px;
}
.bill_tbody tr td:first-child {
  text-align: left;
}
.bill_tbody tr td {
  text-align: right;
  padding: 5px 2px;
  font-size: 8px;
}
.bill_tbody tr td:last-child {
  padding-right: 4px;
}
.bill_tbody tr {
  border-bottom: 1px solid #eee;
}
.bill_tfoot tr td:first-child {
  text-align: left;
  padding-left: 4px;
}
.bill_tfoot tr td {
  text-align: right;
  padding: 5px 2px;
  font-size: 8px;
  font-weight: 600;
  text-transform: capitalize;
}
.bill_tfoot tr td:last-child {
  padding-right: 4px;
}
.bill_bottom_table_wrap {
  padding-left: 0;
  list-style: none;
  width: -webkit-fit-content;
  width: fit-content;
  width: -moz-fit-content;
  margin-left: auto;
}
.bill_bottom_table_item {
  display: flex;
  font-size: 9px;
  text-transform: capitalize;
}
.bill_bottom_table_item_label {
  text-align: right;
  min-width: 100px;
}
.bill_bottom_table_item_value {
  width: 100px;
  text-align: right;
}
.bill_bottom_table_item:last-child {
  border-top: 2px solid #eee;
  padding: 5px 0;
  font-weight: 600;
}
#invoice-POS ::selection {
  background: #f31544;
  color: #fff;
}
#invoice-POS ::moz-selection {
  background: #f31544;
  color: #fff;
}
#invoice-POS h1 {
  font-size: 1.5em;
  color: #222;
}
/* #invoice-POS h2 {
  font-size: 0.9em;
} */
#invoice-POS h3 {
  font-size: 1.2em;
  font-weight: 300;
  line-height: 2em;
}
#invoice-POS p {
  font-size: 0.7em;
  color: #666;
  line-height: 1.2em;
}
#invoice-POS #top,
#invoice-POS #mid,
#invoice-POS #bot {
  /* Targets all id with 'col-' */
  border-bottom: 1px solid #eee;
}
#invoice-POS #top {
  min-height: 100px;
}
#invoice-POS #mid {
  min-height: 80px;
}
#invoice-POS #bot {
  min-height: 50px;
}
#invoice-POS #top .logo {
  height: 60px;
  width: 60px;
  background: url(http://michaeltruong.ca/images/logo1.png) no-repeat;
  background-size: 60px 60px;
}
#invoice-POS .clientlogo {
  float: left;
  height: 60px;
  width: 60px;
  background: url(http://michaeltruong.ca/images/client.jpg) no-repeat;
  background-size: 60px 60px;
  border-radius: 50px;
}
#invoice-POS .info {
  display: block;
  margin-left: 0;
}
#invoice-POS .title {
  float: right;
}
#invoice-POS .title p {
  text-align: right;
}
#invoice-POS table {
  width: 100%;
  border-collapse: collapse;
}
#invoice-POS .tabletitle {
  font-size: 0.5em;
  background: #eee;
}

/* #invoice-POS .item {
  width: 24mm;
} */
#invoice-POS .itemtext {
  font-size: 0.5em;
}
#invoice-POS #legalcopy {
  margin-top: 5mm;
}
.bill_top_box {
  display: flex;
  justify-content: space-between;
}
.bill_enitiy_type {
  font-size: 11px;
  font-weight: 600;
  text-transform: capitalize;
}
/* profile */

.profile_top {
  display: flex;
  justify-content: space-between;
}
.profile_left {
  display: flex;
  flex: 1 1;
}
.profile_img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  min-width: 40px;
}
.profile_details {
  margin-left: 10px;
}
.profile_name {
  font-weight: 500;
  font-size: 14px;
  color: #0e0e2c;
  margin-top: 0;
  margin-bottom: 4px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-transform: capitalize;
}
.profile_party_type {
  font-weight: 400;
  font-size: 12px;
  color: #fc5252;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.option_btn {
  border: none;
  background: transparent;
  color: #4a4a68;
  font-size: 20px;
  min-width: 50px;
}
.option_btn svg {
  font-size: 35px;
}
.profile_party_info {
  font-size: 11px;
  display: flex;
  margin-bottom: 3px;
}
.profile_party_info_label {
  font-size: 12px;
  font-weight: 600;
  margin-right: 2px;
}
.item_sub_details_head {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 5px;
}
.invoice_top_box_desc_date {
  margin-right: 5px;
}

